.table-container::-webkit-scrollbar {
    width: 0px;
    background-color: black;
}

.table-container::-webkit-scrollbar-thumb {
 background-color: rgb(59 7 100 / var(--tw-bg-opacity));
    border-radius: 0px;
}

.table-container::-webkit-scrollbar-track {
    background-color: black;
}