.custom-datepicker {
    background-color: transparent !important; // Quita el color de fondo
    border: none; // Quita el borde
    margin: 0 !important; // Quita los márgenes
    border-radius: 0; // Quita el borde redondeado
    width: auto; // Ajusta el ancho automáticamente
    color: rgba(17, 24, 39, 1) !important;
    font-size: 18px;
    font-weight: bold;
  }
