h2{
    color: white;
}
#banner::after{
    
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%; /* Altura del degradado, ajusta según tus necesidades */
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 100%);   
}
#banner{
    filter: brightness(0.8); 
}
h2{

}
#container-hero3{
    padding: 0%;
}
.la-angle-down{
    font-size: 36px;
}
.icono-flecha{
    padding: 4%;
    color: rgba(143, 0, 255, 1);
}
.la-angle-down:hover{
  color: rgba(143, 0, 255, 1);
  cursor: pointer;
}
.relleno{
    background-color: rgba(143, 0, 255, 1);
    width: 100%;
    height: 50px;
    border-radius: 25px;
    padding: 5px;
    //margin: 0%;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: rgba(51, 0, 91, 1);
    font-size: 33px;
    position: relative;
    display: block;
    overflow: hidden;
    
}
.marquee {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //overflow: hidden;
    min-width: 100%;
    height: 100%;

    
    
}
.marquee span {
    display: inline-block;
    padding-left: 100%;
    text-align: center;
    white-space: nowrap;
    font-size: 33px;
    animation: marquee 20s linear infinite;
    margin-bottom: 8px;
}
.marquee2 span {
    animation-delay: 10s;
}
@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

@media only screen and (max-width: 600px) {
    .la-angle-down{
        font-size: 30px;
    }
    .icono-flecha{
        padding-top: 0%;
        
    }

  }