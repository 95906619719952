/* Estilos para el contenedor principal */



.room-calendar-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    
   
}


/* Estilos para cada sala y sus horarios */
.sala-horario-container {
    margin-left: 0px;
    display: grid;
    
    
}

/* Estilos para cada sala */
.sala-item {
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
    
}

/* Estilos para los horarios disponibles */

.horarios-disponibles{
    display: grid;
    grid-gap: 20px;
    grid-template-areas: "room slots";
    grid-template-columns: 290px auto;
    
    
    
}
.contenedor-horario-slot { 
    display: grid;
    //grid-area: slot;
    grid-template-columns: repeat(6,1fr);
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    margin-right: 5px;
    
}

/* Estilos para cada slot de horario */
.horario-slot2 {
    
    align-content: center;  
    background: transparent;
    border-bottom: 1px solid #e0e3ea;
    border-right: 1px solid #e0e3ea;
    border-top: 1px solid #e0e3ea;
    display: grid;
    //flex: 0 0 auto;
    min-width: 100%;
    height: 90%;
    justify-content: center;
    position: relative;
    text-align: center;
    transition: background .2s ease-out;
    padding: 5px;
    margin: 0%;
    border: 1px solid #8F00FF;
    border-radius: 12px;
    color: white;
    
    //white-space: normal;
    
    
}


.horario-slot2:hover{
    background: #8F00FF;

}

.horario-slot2.cerrado{
    background-color: #8f00ffb3 ;
}
.horario-slot2.reservad{
    background-color: #27b5c49c ;
}

.sala-reservada1{
    background-color: #240d4c;
    color: white;
     
  }


.inputDateReservation{
    display: flex;
    align-items: center;
    justify-content: center;    
    position: sticky !important;
    z-index: 10;
    //background-color: rgba(255, 255, 255, 0.8); /* color de fondo semitransparente */
    backdrop-filter: blur(7px);
    top: 0px ;
    padding: 7px;  
}

#fechaSelector{
    background-color: transparent;
    text-align: center;
    font-size: 30px;
    border: none;
    width: 400px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;

    
}

.react-datepicker-popper .react-datepicker__current-month {
    color: black;
}

.la-calendar-minus{
    font-size: 24px;
    color: #8F00FF;
}

/*.react-datepicker__calendar-icon{
    fill: #8F00FF; 
} 
*/


/*
input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator{
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 2.0;
    filter: invert(0.9);
}
*/


.buttonArrowLeft, .buttonArrowRight{
    margin-right: '5px';
    align-items: 'center';
    
}
.inputDateReservation button{
    font-size: 3.5rem;
    color: #8F00FF;
    margin-left: 15px;
}
.filtros{
    display: none;
}


button:hover{
    color:#33005B;
}
//Estilo para las imagenes de cada sala


.salaImage{
    display: grid;
    grid-area: room;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    width: 300px;
    
   
}



.salaImage img{
  width: 276px;
  height: 164.05px;
  border-radius: 12px;


  
}




.salaImage img:hover{
    cursor: pointer;
}




.salaImage h3{
    font-size: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    position: absolute;
    text-align: center;
    width: 234px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); 
    color: whitesmoke;
    cursor: pointer;
    
}

//Boton reservar
.reservar{
    display: none;
}
.horario-slot2:hover .reservar{
    display: flex;
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: #8F00FF;
    color: #fff;
    border-radius: 999px;
    padding: 3px;
    font-size: 16px;
    width: 80%;
    height: 35px;
    justify-content: center;
    align-items: center;

}
.horario-slot2:hover .precio2{
    position: relative;
    margin-top: 0;
}
.horario-slot2 .precio2{
    position: relative;
    margin-top: 0;
}


//Codigo para version movil

@media screen and (max-width: 1024px) {
    .horarios-disponibles{
        display: grid;
        grid-gap: 1px;
        grid-template-areas:
            "room"  
            "slots";
        grid-template-columns: auto; 
        align-items: center;
        justify-items: center;
        
    }
    .contenedor-horario-slot{
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Tres columnas por fila */
        gap: 5px;
        grid-area: slots;
        width: 90%;
        margin-bottom: 5%;
        
    }


    .salaImage{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0%;
        
    }

    .salaImage img{
        border-radius: 12px;
        width: 100%; 
        height: auto; 
        object-fit: cover;
        max-width: 800px; 
        max-height: 300px;
    }
    
.horario-slot2{ 
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction:column;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: medium;
        border-radius: 12px;
        margin: 2px;
        padding-top: 0;
        
        
    }



    #fechaSelector{
        width: 300px;
        font-size: 20px;
        margin-left: -15px;    
    }

    .la-calendar-minus{
        margin-left: 3px;
        
    }
    
    .inputDateReservation button{
        display: none;
    }

    .NavCalendarRoom{
        display: none;
    }
    .filtros{
        display: flex;
        justify-content: center;
        font-size: 20px;
        float: right;
        width: 111px;
        height: 30px;
        margin-top: 3px;
        align-items: center;
        color: #8F00FF;
    }
    .la-sliders-h{
        transform: rotate(90deg);
    }

    .inputDateReservation{
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;     
    }

    .sala-horario-container{
        margin: 0%;
        padding: 0%;

    }

    .horario-slot2 .precio{
        position: relative;
        margin-top: 0%;

    }
    .horario-slot2:hover .precio{
        position: relative;
        margin-top: 0%;

    }

    
    .horario-slot2 .reservar {
        display: flex;
        position: absolute;
        bottom: 25%;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: #8F00FF;
        color: #fff;
        border-radius: 999px;
        padding: 3px;
        font-size: 16px;
        width: 75%;
        height: 30px ;
        justify-content: center;
        align-items: center;
    }

    .horario-slot2:hover .reservar {
        display: flex;
        position: absolute;
        bottom: 25%;
        left: 50%;
        transform: translate(-50%, 50%);

        border-radius: 999px;
        padding: 3px;
        font-size: 16px;
        width: 75%;
        height: 30px !important;
        justify-content: center;
        align-items: center;
    }
    


  }


  @media screen and (max-width: 600px) {
 
    .horarios-disponibles{
        margin: 5px;
    }
    .contenedor-horario-slot{
        width: 100%;
        
    }

    #fechaSelector{
        width: 200px;
        font-size: 15px;
        margin-left: -15px;
        margin-top: 4px;    
        
    }

    .salaImage img{
        height: 180px;
    }
    
    .horario-slot2{ 
        width: 10%;
        height: 85px;
        margin: 0%;
        padding: 0%;
        padding-bottom: 15%;
        
    }

    .horario-slot2 .precio{
        position: relative;
        margin-top: 0;

    }
    .horario-slot2:hover .precio{
        position: relative;
        margin-top: 0;

    }

        
    .horario-slot2 .reservar {
        display: flex;
        position: absolute;
        bottom: 25%;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: #8F00FF;
        color: #fff;
        border-radius: 999px;
        padding: 3px;
        font-size: 12px;
        width: 85%;
        height: 20px;
        justify-content: center;
        align-items: center;
    }
    

    .horario-slot2:hover .reservar {
        display: flex;
        position: absolute;
        bottom: 25%;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: #8F00FF;
        color: #fff;
        border-radius: 999px;
        padding: 3px;
        font-size: 12px;
        width: 85%;
        height: 20px;
        justify-content: center;
        align-items: center;
    }





  }
